import React from "react";
import { IoMdCheckmark } from "react-icons/io";
import { LuQuote } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-24 py-12 md:py-16 lg:py-16 px-[12%] ">
      <div className="relative ">
        <img
          className="lg:w-[100%] w-full"
          style={{
            maxHeight: "65%",
          }}
          alt="aboutus"
          src={require("../../assets/about.png")}
        />
        <div className="relative lg:absolute right-0  lg:top-[42%] lg:w-[70%]">
          <span className=" justify-end font-mandhor text-right hidden lg:flex -mb-5 text-white mr-6 text-5xl">
            <LuQuote className="" />
          </span>

          <div className=" text-white text-sm   antialiased bg-msky py-10 px-4">
            As one of the leading technologies companies we envision to
            transform and grow our clients’ organizations.
            <br />
            <br /> <b className="font-semibold">CEO, BotHut Systems</b>
          </div>
        </div>
      </div>
      <div>
        <h3 className="text-2xl  text-msky">About Us</h3>

        <h1 className="text-3xl my-2 ">How we work with our clients</h1>

        <p className="text-msgray ">
          One of the leading IT companies having diversified experience in IT
          Solutions, IT Products, digital marketing, On-Prem, and Cloud based
          solutions.
        </p>

        <p className="my-4  py-1 leading-6 text-msgray  border-l-4 border-l-msky pl-8">
          Growing customer expectations. Market-shaping AI. Self-optimizing
          systems. The post-digital age shows no signs of slowing down, and the
          need for new ideas powered by intelligent technologies has never been
          greater. But a vision for the future can’t be realized without
          know-how. We partner with our clients to drive real innovation—the
          kind that turns an idea into an industry—helping them transform and
          grow their organizations. Learn how we work with companies from all
          industries, across the globe to create the New—now.
        </p>

        <button
          onClick={() => navigate("/contact-us")}
          className="px-10 mb-8 py-4  bg-msky text-white"
        >
          Get Started
        </button>

        <ul className="text-lg ">
          <li className="flex items-center antialiased mb-2">
            <IoMdCheckmark className="text-msky mr-2" />
            <p className="text-msgray ">
              We are committed to providing quality IT Services
            </p>
          </li>
          <li className="flex items-center antialiased  mb-2">
            <IoMdCheckmark className="text-msky mr-2" />
            <p className="text-msgray">
              Provided by experts to help challenge critical activities
            </p>
          </li>
          <li className="flex items-center antialiased">
            <IoMdCheckmark className="text-msky mr-2 " />
            <p className="text-msgray">
              Really know the true needs and expectations of customers
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AboutUs;
