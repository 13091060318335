import React from "react";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const data = [
  {
    img: require("../../assets/it/first.png"),
    icon: require("../../assets/it/workflow.png"),
    heading: "Workflow Management",
    subheading: "IT Management",
    details:
      "Following agile methodology, we streamline the workflows which enable us to provide efficient solutions to our clients.",
  },
  {
    img: require("../../assets/it/second.png"),
    icon: require("../../assets/it/recruitment.png"),
    heading: "Recruiting",
    subheading: "Recruitment and Staffing Services",
    details:
      "Extensive experience of our hiring managers sets us apart in the industry to provide the best fit as per needs of the clients.",
  },
  {
    img: require("../../assets/it/third.png"),
    icon: require("../../assets/it/information.png"),
    heading: "IT Practices",
    subheading: "Software Developer",
    details:
      "As the world of technology is changing rapidly our team is adaptive to new coding and industrial practices of the information technology industry.",
  },
];
const IT = () => {
  const navigate = useNavigate();
  return (
    <div className="mb-24 " id="it">
      <h1 className="text-5xl  font-semibold  text-center my-24 ">
        IT Solutions & Projects
      </h1>
      <div className="flex flex-col lg:flex-row   px-[12%] gap-10">
        {data.map((item) => (
          <div className="shadow-lg">
            <img alt={item.heading} src={item.img} />
            <div className=" p-7 bg-[#54595F] text-white flex gap-4 items-center">
              <div>
                <span className="text-white text-4xl">
                  {" "}
                  <img
                    alt={item.heading}
                    className="w-14  "
                    style={{
                      filter:
                        " invert(0.5) sepia(1) saturate(1) hue-rotate(175deg) brightness(10)",
                    }}
                    src={item.icon}
                  />
                </span>
              </div>
              <div className="flex flex-col gap-2 ">
                <h1 className="text-2xl ">{item.heading}</h1>
                <h1>{item.subheading}</h1>
              </div>
            </div>
            <div className="bg-white text-mgray pt-10 pb-16 p-7 ">
              {item.details}
            </div>
          </div>
        ))}
      </div>

      <p className=" flex flex-col md:flex-row  lg:flex-row text-lg items-center justify-center mt-24 px-6">
        Working hard to earn our customers’ trust.
        <span
          onClick={() => navigate("/contact-us")}
          className="text-msky  cursor-pointer flex items-center text-1xl ml-3"
        >
          Get Started <IoIosArrowRoundForward className="text-3xl" />
        </span>
      </p>

      <div
        style={{
          backgroundSize: "100%",
          backgroundAttachment: "fixed",
        }}
        className="py-28 px-[12%] bg-cm-pattern mt-24 relative text-center flex items-center justify-center "
      >
        <h1
          onClick={() => navigate("/contact-us")}
          className="text-4xl cursor-pointer text-white absolute z-10 mx-4 "
        >
          You are just a click away from starting
          <br /> your journey with Bot Hut.
        </h1>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-70" />
      </div>
    </div>
  );
};

export default IT;
