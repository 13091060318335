import React from "react";

const Map = () => {
  return (
    <div className="responsive-map relative ">
      <iframe
      title="bothut"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3026.030982144002!2d-73.9340428!3d40.6732863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25c7f3a4639d9%3A0xc873fd807f149044!2s1286%20Prospect%20Pl%2C%20Brooklyn%2C%20NY%2011213%2C%20USA!5e0!3m2!1sen!2s!4v1705848904744!5m2!1sen!2s"
        className=" h-[30rem] w-full"
        frameborder="0"
        allowfullscreen
      ></iframe>



    </div>
  );
};

export default Map;
