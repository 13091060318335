import React from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";

const listData = [
  {
    id: 1,
    heading: "DIRECT DIGITAL COMMERCE ENABLEMENT",
  },
  {
    id: 2,
    heading: "OMNICHANNEL COMMERCE OPTIMIZATION",
  },
  {
    id: 3,
    heading: "SALES GROWTH AND CHANNEL EFFECTIVENESS",
  },
];
const Second = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-10  py-20 px-[12%] ">
      <div className="bg-white text-white ">
        <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">03.</h1>

        <h2 className="text-black text-5xl  -mt-8">Sales & Commerce</h2>

        <p className="my-6 text-msgray text-justify">
          People buy and sell in totally new ways. This rapid shift to digital
          sales and commerce, accelerated by COVID-19, has given businesses a
          monumental opportunity to create new revenue streams, meet new
          customers and capture the full potential of a $10 trillion market
          that’s poised to nearly triple by 2027.
        </p>

        <p className="mt-6 mb-4 text-msky text-4xl font-semibold">
          How we do it
        </p>

        <ul className="text-black">
          {listData.map((item) => (
            <li
              className="mb-3 text-[20px] flex gap-4 items-center"
              key={item.id}
            >
              <FaArrowAltCircleRight className="text-msky" />
              <h2 className=" text-msgray antialiased">{item.heading}</h2>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex items-center">
        <img
          style={{
            WebkitMaskImage: `url(${require("../../../assets/section/blob.png")})`,
            WebkitMaskSize: "contain",
            WebkitMaskAttachment: "unset",

            WebkitMaskRepeat: "no-repeat",
          }}
          src={require("../../../assets/section/second.jpg")}
          alt="SALES & COMMERCE"
        />
      </div>
    </div>
  );
};

export default Second;
