import React from "react";
import Footer from "../component/Footer/Footer";
import NavBar from "../component/NavBar/NavBar";

const NotFound = () => {
  return (
    <div className="bg-black">
      <NavBar />

      <div className="flex items-center justify-center py-16 ">
        <img
          alt="404"
          className="w-[80%] lg:w-[50%] md:w-[45%] "
          src={require("../assets/nopage.png")}
        />
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
