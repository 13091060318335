import React from "react";
import ApplyNow from "../../ApplyNow/ApplyNow";

const One = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${require("../../../assets/section/one.jpg")})`,
      }}
      className="  bg-fixed bg-cover text-white  py-20 px-[12%]"
    >
      <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">02.</h1>

      <h2 className="text-white text-5xl -mt-8  ">Business Strategy</h2>

      <p className="my-6">
        <strong>Harness change to create 360° value</strong> In this
        ever-changing world, companies need to think fast and stay <br /> agile.
        And that requires strategies that work in the real world. With
        experience across the value chain, end-to-end, <br /> only BotHut
        Systems helps clients create strategies that come not just from knowing,
        but from the know-how of doing.
      </p>

      <p className="my-6">
        Our integrated model enables 360° value creation by giving our
        strategists the advantage of BotHut Systems <br /> insights from AI and
        data science and deep industry expertise, combined with the experience
        of efficiently <br /> operating business functions, optimizing and
        running supply chains, designing and implementing <br /> technology, and
        building resilient operating models and cultures.
      </p>

      <p className="my-6">
        Accenture Strategy leverages this advantage in our work with boards,
        CEOs, and C-suite executives to ,<br /> create 360° value for
        shareholders and stakeholders by defining and answering their most
        strategic <br /> business questions on topics like growth,
        profitability, technology-driven transformation, M&amp;A, operating{" "}
        <br /> models, and sustainability.
      </p>

      <ApplyNow />
    </div>
  );
};

export default One;
