import React, { useEffect } from "react";
import NavBar from "../component/NavBar/NavBar";
import Footer from "../component/Footer/Footer";
import { useNavigate } from "react-router-dom";

const ComingSoon = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <div>
      <NavBar
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
      <div className="bg-fon-pattern bg-center bg-cover relative flex justify-center items-center">
        {/* Overlay */}
        <div className="absolute inset-0 bg-[#000000] opacity-50"></div>

        {/* Content */}
        <div className=" p-28 lg:p-56 md:p-52 flex items-end relative text-center ">
          <h1 className={`text-white  text-5xl `}>Coming soon</h1>
        </div>
      </div>
      <img
        width="800"
        height="534"
        src="https://apaxsys.com/wp-content/uploads/2022/08/pexels-tim-douglas-6567546-1024x683.jpg"
        alt=""
        srcSet="https://apaxsys.com/wp-content/uploads/2022/08/pexels-tim-douglas-6567546-1024x683.jpg 1024w, https://apaxsys.com/wp-content/uploads/2022/08/pexels-tim-douglas-6567546-300x200.jpg 300w, https://apaxsys.com/wp-content/uploads/2022/08/pexels-tim-douglas-6567546-768x512.jpg 768w, https://apaxsys.com/wp-content/uploads/2022/08/pexels-tim-douglas-6567546-1536x1024.jpg 1536w, https://apaxsys.com/wp-content/uploads/2022/08/pexels-tim-douglas-6567546-2048x1365.jpg 2048w"
        sizes="(max-width: 800px) 100vw, 800px"
        style={{
          maskImage:
            'url("https://apaxsys.com/wp-content/plugins/elementor/assets//mask-shapes/blob.svg")',
          maskSize: "contain",
          maskPosition: "0% 50%",
          maskRepeat: "no-repeat",
          verticalAlign: "middle",
          display: "inline-block",
          height: "auto",
          maxWidth: "100%",
          border: "0px none rgb(51, 51, 51)",
          borderRadius: "0px",
          boxShadow: "none",
          boxSizing: "border-box",
          borderStyle: "none",
        }}
      />
      <Footer
        onAboutPress={() => navigate("/?section=aboutus")}
        onServicePress={() => navigate("/?section=service")}
      />
    </div>
  );
};

export default ComingSoon;
