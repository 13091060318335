import React, { useEffect, useState } from "react";
import serviceList from "../../data/services.json";
const Services = ({
  onITProduct,
  onBusiness,
  onDigital,
  onTech,
  onSupply,
  onProcess,
  onApplication,
  onHigh,
}) => {
  const [is, setIs] = useState(false);

  useEffect(() => {
    var header = document.getElementById("myHeader");
    const sticky = header.offsetTop;

    const changeNavBg = () => {
      if (window.scrollY + 30 >= sticky) {
        setIs(true);
      } else {
        setIs(false);
      }
    };
    window.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);

  return (
    <div id="myHeader" className="px-4 my-16 relative header">
      <h1 className="text-3xl my-4 text-msky ">Our Services</h1>

      <ul
        className={`justify-center bg-msky p-6 mb-20 mt-12 hidden ${
          is ? "fixed" : "sticky"
        } lg:flex top-9 left-0 w-full z-50  text-center`}
      >
        {serviceList.map((item, index) => (
          <li
            onClick={() => {
              if (item.id === 0) {
                onITProduct();
              } else if (item.id === 1) {
                onBusiness();
              } else if (item.id === 2) {
                onDigital();
              } else if (item.id === 3) {
                onTech();
              } else if (item.id === 4) {
                onSupply();
              } else if (item.id === 5) {
                onProcess();
              } else if (item.id === 6) {
                onApplication();
              } else if (item.id === 7) {
                onHigh();
              }
            }}
            className={` border-r-white cursor-pointer text-white hover:antialiased `}
            key={item.id}
          >
            <span className="flex items-center">
              <h1 className="text-xs lg:text-sm  hover:text-msky hover:bg-white">
                {item.name}
              </h1>
              {serviceList.length - 1 !== index && <h1 className="mx-3">|</h1>}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Services;
