import React from "react";
import ApplyNow from "../../ApplyNow/ApplyNow";


const Seven = () => {

    
  return (
   
    <div style={{
        backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${require('../../../assets/section/seven.jpg')})`
}} className="  bg-fixed bg-cover text-white  py-20 px-[12%]">
 <h1 className="font-mandhor text-[120px] text-[#6EC1E470]">08.</h1>

 <h2 className="text-white text-5xl  -mt-8">High Profile PR</h2>

 <p className="my-6">We partner with brands, both established and emerging, corporations, global interests, national corporations & consumer <br/> companies, start-up technology companies, high-profile individuals, regional businesses, and others to help them define and achieve their <br/> strategic PR goals.</p>
  
 <p className="my-6">The agency works in various practice areas across B2C and B2B categories, designed to raise their clients’ brand, sales, and <br/> bottom-line results. They implement powerful, proactive, and passionate media relations efforts and offer a comprehensive range of integrated <br/> marketing services to meet every client’s unique needs.</p>

 <p className="my-6">Public Relations’ services include integrated marketing, public relations, strategic planning, event management, reputation <br/> management, influencer & celebrity marketing, product integration, and social media & digital marketing. </p>

<ApplyNow/>
    </div>
   
  );
};

export default Seven;
