import React from "react";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const ApplyNow = ({ dark = false }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate("/contact-us")}
      className={
        dark
          ? "  px-2 text-msky cursor-pointer text-2xl  flex gap-2 items-center border-2 border-msky hover:bg-msky hover:text-white"
          : " px-2 text-white cursor-pointer text-2xl  flex gap-2 items-center border-2 border-white hover:bg-msky hover:text-white hover:border-msky"
      }
    >
      <IoIosArrowRoundForward className="text-5xl" /> Get Started
    </button>
  );
};

export default ApplyNow;
